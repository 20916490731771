import { AppUser } from "@farmact/model/src/model/AppUser";
import { useEffect } from "react";
import { appVersion } from "@/config/config";
import { Firebase } from "@/firebase";

export function useReportAppVersion(appUserId: AppUser["id"] | undefined) {
    useEffect(() => {
        if (!appUserId) {
            return;
        }
        Firebase.instance().updateAppUser(appUserId, {
            lastSeenAppVersion: appVersion,
        });
    }, [appUserId]);
}
