import { ActivityType, Employee } from "@farmact/model/src/model/Employee";
import { CommonInternalWorkType } from "@farmact/model/src/model/workTypes/CommonInternalWorkType";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { ChooseOperatingUnitModalProps } from "@/components/internalWork/ChooseOperatingUnitModal/ChooseOperatingUnitForInternalWorkModal";
import { useChooseOperatingUnitForInternalWorkModal } from "@/components/internalWork/hooks/useChooseOperatingUnitForInternalWorkModal";
import { useStopInternalWork } from "@/components/internalWork/hooks/useStopInternalWork";
import { InternalWorkNoteModalProps } from "@/components/internalWork/InternalWorkGrid/InternalWorkCard/InternalWorkNoteModal/InternalWorkNoteModal";
import { StopOrderModalProps } from "@/components/orders/Tasks/StopOrderModal/StopOrderModal";
import { useStopOrderModal } from "@/components/orders/Tasks/StopOrderModal/useStopOrderModal";
import { useToastContext } from "@/components/ToastContext";
import { Firebase } from "@/firebase";
import { useDocumentData } from "@/firebase/dataHooks";

type UseControlWorkdayReturn = {
    start: () => Promise<void>;
    stop: () => Promise<void>;
    stopOrderModalProps: StopOrderModalProps;
    stopInternalWorkModalProps: InternalWorkNoteModalProps;
    chooseOperatingUnitModalProps: ChooseOperatingUnitModalProps;
};

export function useControlWorkday(options?: {
    waitForDatabaseCommit?: boolean;
    employee?: Employee;
}): UseControlWorkdayReturn {
    const { employee: uncontrolledEmployee } = useUserContext();
    const { onMessage } = useToastContext();

    const employee = options?.employee ?? uncontrolledEmployee;

    const orderId = useMemo(
        () => (employee?.currentActivity?.type === ActivityType.ORDER ? employee.currentActivity.orderId : undefined),
        [employee?.currentActivity]
    );

    const [order] = useDocumentData(orderId !== undefined ? Firebase.instance().getOrderRef(orderId) : undefined, [
        orderId,
    ]);

    const stopOrderWorkModal = useStopOrderModal(order, {
        waitForDatabaseCommit: options?.waitForDatabaseCommit,
        employee,
    });
    const stopInternalWorkModal = useStopInternalWork({
        waitForDatabaseCommit: options?.waitForDatabaseCommit,
        employee,
    });
    const chooseOperatingUnit = useChooseOperatingUnitForInternalWorkModal();

    const start = async () => {
        if (!employee) {
            onMessage("Konnte Mitarbeiter nicht identifizieren.", "warning");
            return;
        }
        const chosenOperatingUnitId = await chooseOperatingUnit.choose();
        Firebase.instance().startNewActivityForEmployee(
            {
                type: ActivityType.INTERNAL,
                startDateTime: dayjs().toISOString(),
                internalWorkType: CommonInternalWorkType.USE,
                operatingUnitId: chosenOperatingUnitId,
            },
            employee,
            false
        );
    };

    const stop = async () => {
        if (employee?.currentActivity?.type === ActivityType.INTERNAL) {
            await stopInternalWorkModal.stop();
        }
        if (employee?.currentActivity?.type === ActivityType.ORDER) {
            await stopOrderWorkModal.stop();
        }
    };

    return {
        start,
        stop,
        stopOrderModalProps: stopOrderWorkModal.modalProps,
        stopInternalWorkModalProps: stopInternalWorkModal.modalProps,
        chooseOperatingUnitModalProps: chooseOperatingUnit.modalProps,
    };
}
